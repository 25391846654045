//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

//import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {


  pageTiles: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden'
  },

  pageTile__small__tile: mq({
    flexGrow: '0',
    flexShrink: '0',
    padding: '10px',

    //for type1
    flexBasis: ['100%', null, '50%', '33.3%'],
    maxWidth: ['100%', null, '50%', '33.3%'],

    '&.type2.useSlider': {
      flexBasis: '100%',
      maxWidth: '100%',
      height: '100%',

    },

  }),

  pageTile__small__tile__content: {
    '&:hover': {
      textDecoration: 'none',
      '.pageTile__small__container': {
        //backgroundImage: `linear-gradient(to right, ${variables.colorBlue} 0%, ${variables.colorBlue} 20%, ${variables.colorGreen} 100%)`
        backgroundImage: `linear-gradient(to right, ${variables.colorBlue} 0%, ${variables.colorGreen} 100%)`
      }
    }

  },

  pageTile__small__tile__flip__wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    //height: '100%',
    //cursor: 'pointer',
    position: 'relative',
    flexWrap: 'wrap',
    backgroundColor: '#F9F8F8',
    //boxShadow: '1px 2px 10px rgb(190 190 190)'
  },

  pageTile__small__tile__bg: mq({
    flex: ['1 0 100%'],
    maxWidth: ['100%'],
    paddingTop: ['56.25%'],
    position: 'relative',
    '&.odd': mq({
      order: [0]
    }),
  }),

  pageTile__small__tile__bg__inner: mq({
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    //transition: 'all .2s',
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100%',
  }),

  pageTile__small__content__details: mq({
    flex: ['1 0 100%'],
    maxWidth: ['100%'],
    position: 'relative',
    marginTop: ['-1px'],
    '.pageTile__small__container': mq({
      position: ['static'],
      width: ['100%'],
      height: ['100%'],
      clipPath: ['polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%)'],
      //backgroundColor: variables.colorBlue,
      backgroundImage: `linear-gradient(to right, ${variables.colorBlue}, ${variables.colorBlue})`,  //' linear-gradient(to right, red , yellow)',
      display: 'flex',
      flexDirection: 'column',
      //alignItems: 'center',
      paddingLeft: ['2rem'],
      paddingRight: ['3rem'],
      paddingTop: ['1rem'],
      paddingBottom: ['2rem'],
      //transition: 'all 1s ease-in-out',
      //transition: opacity 1s linear;
      // '&.odd' : {
      //     //left : '0%',
      //     clipPath : 'polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%)',
      // },
      '.pageTile__small__container__inner': {
        //display: 'flex'
      }
    }),
  }),

  pageTile__small__title: mq({
    fontFamily: variables.familyHeader,
    color: 'white',
    fontSize: ['24px',null,'30px'],
    fontWeight: 600
  }),

  pageTile__small__desc: mq({
    marginTop: '0.5rem',
    color: 'white',
    fontWeight: 300,
    fontSize: ['1rem',null,'0.8rem'],
  })

}