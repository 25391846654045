//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

//import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    },

    pageTile__big__tile : {
        flex: '0 0 100%',
        maxWidth: '100%',
        padding: '10px',
    },

    pageTile__big__tile__content : {
        '&:hover' : {
            textDecoration: 'none',
            '.pageTile__big__container' : {
                //backgroundImage: `linear-gradient(to right, ${variables.colorBlue} 0%, ${variables.colorBlue} 20%, ${variables.colorGreen} 100%)`
                backgroundImage: `linear-gradient(to right, ${variables.colorBlue} 0%, ${variables.colorGreen} 100%)`
            }
        }
    },

    pageTile__big__tile__flip__wrapper : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        //cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap',
        backgroundColor: '#F9F8F8',
        //boxShadow: '1px 2px 10px rgb(190 190 190)'
    },

    pageTile__big__tile__bg : mq({
        flex: ['1 0 100%',null,'1 0 47%'],
        maxWidth : ['100%',null,'47%'],
        paddingTop: ['56.25%', '40%', '33%'],
        position: 'relative',
        '&.odd' : mq({
            order : [0,null,1]
        }),
    }),

    pageTile__big__tile__bg__inner : mq({
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //transition: 'all .2s',
        position: 'absolute',
        top: '0px',
        width: '100%',
        height: '100%',
    }),

    pageTile__big__content__details : mq({
        flex: ['1 0 100%',null,'1 0 53%'],
        maxWidth : ['100%',null,'53%'],
        position: 'relative',
        marginTop : ['-1px',null,'0px'],
        '.pageTile__big__container' : mq({
            position: ['static', null, 'absolute'],
            width : ['100%',null,'114%'],
            height: ['100%'],
            //top : '50%',
            left : '-14%',
            //transform : ['translate(0, 0)',null,'translate(-50%, -50%)'],
            //clipPath : ['polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%)',null,'polygon(0% 0%, 100% 0%, 100% 100%, 12% 100%)'],
            clipPath : ['polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%)',null,'polygon(12% 0%, 100% 0%, 100% 100%, 12% 100%)'],
            //backgroundColor: variables.colorBlue,
            backgroundImage: `linear-gradient(to right, ${variables.colorBlue}, ${variables.colorBlue})`,  //' linear-gradient(to right, red , yellow)',
            display: 'flex',
            flexDirection : 'column',
            //alignItems: 'center',
            paddingLeft: ['2rem',null,'2rem','5rem'],
            paddingRight: ['3rem',null,'3rem', '4rem'],
            paddingTop : ['1rem',null,'1rem', '3rem'],
            paddingBottom: ['2rem',null,'1rem', '1rem'],
            //transition: 'all 1s ease-in-out',
            //transition: opacity 1s linear;
            '&.odd' : {
                left : '0%',
                clipPath : 'polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%)',
            },
            '.pageTile__big__container__inner' : {
                //display: 'flex'
            }
        })
    }),

    pageTile__big__title : mq({
        fontFamily: variables.familyHeader,
        color: 'white',
        fontSize: ['24px',null,'36px'],
        fontWeight: 600
    }),

    pageTile__big__desc : mq({
        marginTop: '0.5rem',
        color: 'white',
        fontWeight : 300
    })
}
