const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#004B61',
  colorGreen : '#8DC63F',
  colorLightGreen : '#BBDD8C',
  colorGrey : '#454345',
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"eurostile",serif',

  primaryColor: colors.colorGreen,
  fontColor: colors.colorGrey,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;