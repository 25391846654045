//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../..';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc2(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__big', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      css={style.pageTiles}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = page.colorTheme;
  const index = props.idx;
  
  return (

    <div css={style.pageTile__big__tile} className="pageTile__big__tile">
      <SiteLink className="pageTile__big__tile__content" css={style.pageTile__big__tile__content} to={disableLink ? '' : page.pageUrl}>
        <div css={style.pageTile__big__tile__flip__wrapper} className="pageTile__big__tile__flip__wrapper" data-aos='fade-up' data-aos-offset="50" data-aos-anchor-placement="top-center">

          <div className={`pageTile__big__tile__bg ${index % 2 === 0 ? 'odd' : 'even'}`} css={style.pageTile__big__tile__bg}>
            <div className="pageTile__big__tile__bg__inner" css={style.pageTile__big__tile__bg__inner} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          </div>

          <div css={style.pageTile__big__content__details} className={`pageTile__big__content__details ${colorTheme} ${index % 2 === 0 ? 'odd' : 'even'}`} >

            <div className={`pageTile__big__container ${index % 2 === 0 ? 'odd' : 'even'}`}>

              <div className="pageTile__big__container__inner">
                <div css={style.pageTile__big__title} className="pageTile__big__title">
                  {page.pageTitle}
                </div>
                <div css={style.pageTile__big__desc} className="pageTile__big__desc">
                  {page.tileDesc || page.description}
                </div>
              </div>

            </div>

          </div>


        </div>
      </SiteLink>
    </div>
  )
}